import { RadioWrapper, RadioButton } from "./Radio.styled";
import { CustomRadio } from "./CustomRadio";

type RadioProps = {
  options: any[];
  value: any;
  onChange?: (params?: any) => void;
  disabled?: boolean;
  style?: any;
};

const Radio = ({
  options = [],
  value,
  onChange,
  disabled,
  style,
  ...props
}: RadioProps) => {
  return (
    <RadioWrapper
      row
      value={value}
      onChange={(e) => onChange && onChange(e.target.value)}
      style={style}
      {...props}
    >
      {options.map((v, i) => (
        <RadioButton
          key={i}
          value={v.value}
          label={v.label}
          control={<CustomRadio />}
          disabled={disabled || v.disabled}
        />
      ))}
    </RadioWrapper>
  );
};

export default Radio;
