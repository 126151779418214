import {
  AppBar,
  Button,
  Dialog,
  Fab,
  IconButton,
  Paper,
  Slide,
  Toolbar,
  Typography,
  Zoom,
  Box,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, useCallback, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { DriveView, DriveViewProvider, useDriveView } from "./DriveView";
import { useApi } from "providers/api";
import { useUser } from "providers/user";
import Icon from "components/Icon";
import FileUploadIcon from "@mui/icons-material/FileUpload";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type SelectFileDialogProps = {
  open: boolean;
  onClose: () => void;
  onSelect: (value: any) => void;
};

export function SelectFileDialog({
  open,
  onClose,
  onSelect,
}: SelectFileDialogProps) {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <SelectFileDialogContent onSelect={onSelect} onClose={onClose} />
    </Dialog>
  );
}

type SelectFileDialogAppBarProps = {
  histories: any;
  directory: any;
  onClose: () => void;
  onBack: () => void;
};

function SelectFileDialogAppBar({
  histories,
  directory,
  onClose,
  onBack,
}: SelectFileDialogAppBarProps) {
  const { selecting, setSelecting, selectAll, deselectAll, isSelectedAll } =
    useDriveView();

  const onClickSelect = (e: any) => {
    e.stopPropagation();
    setSelecting(true);
  };

  const onClickSelectAll = (e: any) => {
    e.stopPropagation();
    selectAll();
  };

  const onClickDeselectAll = (e: any) => {
    e.stopPropagation();
    deselectAll();
  };

  const onClickCancelSelect = (e: any) => {
    e.stopPropagation();
    setSelecting(false);
    deselectAll();
  };

  const onClickBack = (e: any) => {
    e.stopPropagation();
    onBack();
  };

  const onClickClose = (e: any) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <AppBar
      position="sticky"
      color={"inherit"}
      sx={{
        boxShadow: "none",
        button: {
          height: "34px",
        },
      }}
    >
      <Toolbar>
        {selecting ? (
          <Button color="inherit" onClick={onClickCancelSelect}>
            취소
          </Button>
        ) : histories.length > 1 ? (
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClickBack}
            aria-label="close"
            sx={{ width: "40px" }}
          >
            <Icon variant="back_arrow" />
          </IconButton>
        ) : (
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClickClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        )}

        <Typography
          sx={{ ml: 2, flex: 1, display: "flex", justifyContent: "center" }}
          variant="h6"
          component="div"
          align="center"
        >
          <Box
            sx={{
              maxWidth: "510px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textAlign: "center",
              whiteSpace: "nowrap",
            }}
          >
            {directory?.name ?? ""}
          </Box>
        </Typography>
        {selecting ? (
          isSelectedAll ? (
            <Button color="inherit" onClick={onClickDeselectAll}>
              전체해제
            </Button>
          ) : (
            <Button color="inherit" onClick={onClickSelectAll}>
              전체선택
            </Button>
          )
        ) : (
          <>
            <Button color="inherit" onClick={onClickSelect}>
              선택
            </Button>
            <Button color="inherit" onClick={onClickClose}>
              취소
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

function UploadButton({ onUpload }: any) {
  const { selecting, selected } = useDriveView();

  const onClickUpload = (e: any) => {
    e.stopPropagation();
    onUpload(selected);
  };

  return (
    <Zoom in={selecting} unmountOnExit>
      <Fab
        sx={{
          position: "fixed",
          bottom: 16,
          left: "50%",
          transform: "translate(-50%, 0)",
        }}
        color="primary"
        variant="extended"
        onClick={onClickUpload}
      >
        <FileUploadIcon />
        업로드
      </Fab>
    </Zoom>
  );
}

type SelectFileDialogContentProps = {
  onSelect: (value: any) => void;
  onClose: () => void;
};

export function SelectFileDialogContent({
  onSelect,
  onClose,
}: SelectFileDialogContentProps) {
  const api = useApi();
  const { user } = useUser();
  const [histories, setHistories] = useState([]);
  const [directory, setDirectory] = useState(null);

  const loadDrive = useCallback(
    async (clinicId: string) => {
      const res = await api.getClinicDrive(clinicId);
      const payload = await res.json();

      pushHistory(payload.data.root.id);
    },
    [api]
  );

  const pushHistory = (directoryId: string) => {
    setDirectory(null);
    setHistories((histories: any) => {
      if (histories[histories.length - 1] === directoryId) return histories;

      return [...histories, directoryId];
    });
  };

  useEffect(() => {
    loadDrive(user.clinic.id);
  }, [loadDrive, user]);

  const onEnterDirectory = (directoryId: string) => {
    pushHistory(directoryId);
  };

  const loadDirectory = useCallback(
    async (id: string) => {
      const res = await api.getDirectory(id);
      const payload = await res.json();

      setDirectory(payload.data);
    },
    [api]
  );

  useEffect(() => {
    if (!histories.length) return;
    loadDirectory(histories[histories.length - 1]);
  }, [loadDirectory, histories]);

  const onUpload = (selected: any) => {
    onSelect(selected);
  };

  const onBack = () => {
    setDirectory(null);
    setHistories((histories) => histories.slice(0, histories.length - 1));
  };

  if (!histories.length) return <></>;
  return (
    <Paper
      sx={{
        height: "100%",
        overflow: "auto",
        backgroundColor: "grey.background",
      }}
    >
      <DriveViewProvider
        directoryId={histories[histories.length - 1]}
        penchartDrive={true}
      >
        <SelectFileDialogAppBar
          histories={histories}
          directory={directory}
          onClose={onClose}
          onBack={onBack}
        />
        <DriveView readonly onEnterDirectory={onEnterDirectory} />
        <UploadButton onUpload={onUpload} />
      </DriveViewProvider>
    </Paper>
  );
}
