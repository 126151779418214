import { Box, Typography } from "@mui/material";
import { useApi } from "providers/api";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Directory } from "./Directory";
import { DriveSpeedDial } from "./DriveSpeedDial";
import { File } from "./File";
import { TextInput } from "components/TextInput";
import { SortField, SortType } from "./SortField";
import update from "immutability-helper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircularProgress from "@mui/material/CircularProgress";
import {
  LeftSection,
  TextInputWrapper,
  DriveItemGrid,
} from "components/DriveView/DriveView.styled";
import { Button } from "components/Button";

function sortCmp(a: any, b: any, orderBy: any) {
  switch (orderBy) {
    case SortType.CreatedAtAsc:
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    case SortType.CreatedAtDesc:
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    case SortType.NameAsc:
      return a.name === b.name ? 0 : a.name > b.name ? -1 : 1;
    case SortType.NameDesc:
      return a.name === b.name ? 0 : a.name > b.name ? 1 : -1;
    default:
      throw Error(`sorting by ${orderBy} is not supported`);
  }
}

const DriveSection = ({ title, children }: { title: any; children: any }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <Box>
        <Typography sx={{ fontSize: "14px", textIndent: "10px" }}>
          {title}
        </Typography>
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};

const DriveViewContext = createContext<any>(null);

export const DriveViewProvider = ({
  directoryId,
  penchartDrive,
  children,
}: {
  directoryId?: any;
  penchartDrive?: any;
  children?: any;
}) => {
  const api = useApi();

  const [directories, setDirectories] = useState([]);
  const [files, setFiles] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [orderBy, setOrderBy] = useState(SortType.CreatedAtDesc);
  const [pagination, setPagination] = useState({
    pages: 1,
    total: 0,
    limit: 30,
  });
  const [moreLoading, setMoreLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");

  const loadDirectories = useCallback(async () => {
    const res = await api.getDirectories({
      directoryId,
      orderBy: orderBy ?? "createdAt desc",
    });
    const payload = await res.json();

    setDirectories(payload.data);
  }, [api, directoryId]);

  const loadFiles = useCallback(async () => {
    try {
      const params: {
        parentId: string;
        limit: number;
        page: number;
        orderBy: string;
        name?: string;
      } = {
        parentId: directoryId,
        limit: 30,
        page: page ?? 1,
        orderBy: orderBy ?? "createdAt desc",
      };
      if (searchKeyword !== "") {
        params.name = searchKeyword;
      }

      const res = await api.getFiles(params);
      const payload = await res.json();
      setFiles(payload.data);
      setPagination(payload.pagination);
      setPage(1);
    } finally {
      setMoreLoading(false);
    }
  }, [api, directoryId, orderBy, searchKeyword]);

  const reload = () => {
    loadDirectories();
    loadFiles();
  };

  useEffect(() => {
    loadDirectories();
    loadFiles();
  }, [directoryId, loadDirectories, loadFiles]);

  const [selecting, setSelecting] = useState(false);
  const [selected, setSelected] = useState<any>({
    directories: [],
    files: [],
  });

  const selectAll = () => {
    setSelected({
      directories: directories.map((d: any) => d.id),
      files: files.map((f: any) => f.id),
    });
  };

  const deselectAll = () => {
    setSelected({
      directories: [],
      files: [],
    });
  };

  const isSelectedAll = useMemo(
    () =>
      selected.directories.length === directories.length &&
      selected.files.length === files.length,
    [directories, files, selected]
  );

  const onClickMore = async () => {
    try {
      const params: {
        parentId: string;
        limit: number;
        page: number;
        orderBy: string;
        name?: string;
      } = {
        parentId: directoryId,
        limit: 30,
        page: page + 1 ?? 1,
        orderBy: orderBy ?? "createdAt desc",
      };
      if (searchKeyword !== "") {
        params.name = searchKeyword;
      }

      const res = await api.getFiles(params);
      const payload = await res.json();

      setFiles((file: any) => {
        return [
          ...file,
          ...payload.data.filter(
            (f: any) => file.find((fi: any) => fi.id === f.id) === undefined
          ),
        ];
      });
      setPage((page) => page + 1);
    } finally {
      setMoreLoading(false);
    }
  };

  const onChangeOrderBy = (order: any) => {
    setMoreLoading(true);
    setOrderBy(order);
    setPage(1);
    setFiles([]);
  };

  return (
    <DriveViewContext.Provider
      value={{
        penchartDrive,
        directoryId,
        directories,
        files,
        selecting,
        setSelecting,
        selected,
        setSelected,
        reload,
        selectAll,
        deselectAll,
        isSelectedAll,
        page,
        setPage,
        pagination,
        orderBy,
        onChangeOrderBy,
        onClickMore,
        moreLoading,
        setMoreLoading,
        searchKeyword,
        setSearchKeyword,
      }}
    >
      {children}
    </DriveViewContext.Provider>
  );
};

export const useDriveView = () => {
  return useContext(DriveViewContext);
};

export function DriveView({
  onEnterDirectory = null,
  onEnterFile = null,
  readonly = false,
}: {
  onEnterDirectory?: any;
  onEnterFile?: any;
  readonly?: any;
}) {
  const {
    directoryId,
    files,
    directories,
    selecting,
    selected,
    setSelected,
    page,
    pagination,
    orderBy,
    onChangeOrderBy,
    onClickMore,
    moreLoading,
    setMoreLoading,
    searchKeyword,
    setSearchKeyword,
  } = useDriveView();
  const [inputKeyword, setInputKeyword] = useState("");

  const onClickSearch = () => {
    setSearchKeyword(inputKeyword);
  };

  const searchedDirectories = useMemo(() => {
    const keyword = searchKeyword.trim();

    return directories
      .filter((d: any) => {
        if (keyword.length === 0) return true;

        return d.name.includes(keyword);
      })
      .sort((a: any, b: any) => sortCmp(a, b, orderBy));
  }, [directories, orderBy, searchKeyword]);

  const onChangeDirectoryChecked = (id: any, checked: any) => {
    if (checked) {
      setSelected(
        update(selected, {
          directories: {
            $push: [id],
          },
        })
      );
    } else {
      setSelected(
        update(selected, {
          directories: {
            $splice: [[selected.directories.indexOf(id), 1]],
          },
        })
      );
    }
  };

  const onChangeFileChecked = (id: any, checked: any) => {
    if (checked) {
      setSelected(
        update(selected, {
          files: {
            $push: [id],
          },
        })
      );
    } else {
      setSelected(
        update(selected, {
          files: {
            $splice: [[selected.files.indexOf(id), 1]],
          },
        })
      );
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
          p: "20px 30px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <LeftSection>
            <TextInputWrapper>
              <TextInput
                placeholder="폴더명, 파일명을 검색하세요."
                value={inputKeyword}
                onChange={setInputKeyword}
              />
            </TextInputWrapper>
            <Button style={{ height: "29px" }} onClick={onClickSearch}>
              조회
            </Button>
          </LeftSection>
          <SortField value={orderBy} onChange={onChangeOrderBy} />
        </Box>
        <DriveSection title="폴더">
          <DriveItemGrid>
            {searchedDirectories.map((d: any) => {
              return (
                <Directory
                  key={d.id}
                  directory={d}
                  readonly={readonly}
                  selecting={selecting}
                  checked={selected.directories.includes(d.id)}
                  onChangeChecked={(checked: any) =>
                    onChangeDirectoryChecked(d.id, checked)
                  }
                  onEnter={() => onEnterDirectory?.(d.id)}
                />
              );
            })}
          </DriveItemGrid>
        </DriveSection>
        <DriveSection title="파일">
          <DriveItemGrid>
            {files.map((f: any) => (
              <File
                key={f.id}
                file={f}
                selecting={selecting}
                checked={selected.files.includes(f.id)}
                onChangeChecked={(checked: any) =>
                  onChangeFileChecked(f.id, checked)
                }
                onEnter={() =>
                  onEnterFile?.(
                    f.id,
                    files.map((f: any) => f.id)
                  )
                }
              />
            ))}
          </DriveItemGrid>
        </DriveSection>
        {pagination.pages > page && files.length > 0 && !moreLoading && (
          <Box
            onClick={() => {
              setMoreLoading(true);
              onClickMore();
            }}
          >
            <Button
              color="mix"
              styled="outline"
              style={{
                width: "100%",
                height: "52px",
                border: "solid 1px #DEE2EC",
              }}
            >
              <span>더 보기</span>
              <ExpandMoreIcon />
            </Button>
          </Box>
        )}
        {moreLoading && (
          <Box
            sx={{ margin: "20px 0" }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
      {!readonly && <DriveSpeedDial directoryId={directoryId} />}
    </>
  );
}
