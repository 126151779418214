import { useEffect, useCallback, forwardRef, ComponentType } from "react";
import { observer } from "mobx-react";
import { format, getMonth, getYear } from "date-fns";
import _DatePicker from "react-datepicker";
import "assets/styles/datepicker.css";
import { useHoliday } from "../store/holiday";
import Icon from "./Icon";
import { ToolbarButton as _ToobarButton } from "components/ToolbarButton";
import _NativeSelect from "components/NativeSelect2";
import { Wrapper, DatePickerWrapper, DateText } from "./DateInput.styled";

const ToolbarButton = _ToobarButton as ComponentType<any>;
const NativeSelect = _NativeSelect as ComponentType<any>;
const DatePicker = _DatePicker as ComponentType<any>;

function range(start: number, end: number) {
  return Array(end - start + 1)
    .fill("")
    .map((_, idx) => start + idx);
}
const years = range(1900, 2100);
const months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];

type DateInputProps = {
  className?: string;
  placeholder?: string;
  dateFormat?: string;
  value?: any;
  maxDate?: Date | string;
  minDate?: Date | string;
  onChange?: (value?: any, event?: any) => void;
  disabled?: boolean;
  width?: string;
  style?: any;
  error?: string;
  autoFocus?: boolean;
  customInput?: any;
  readOnly?: boolean;
  selected?: any;
  popperPlacement?: any;
  popperModifiers?: any;
};

const DateInput = forwardRef(
  (
    {
      className,
      placeholder,
      dateFormat = "yyyy년 MM월 dd일",
      value,
      onChange,
      disabled,
      maxDate,
      minDate,
      width,
      error,
      autoFocus,
      ...props
    }: DateInputProps,
    ref
  ): any => {
    const holiday: any = useHoliday();

    const getHoliday = useCallback(
      async (date: any) => {
        (await holiday) && holiday.load(date ?? new Date());
      },
      [holiday]
    );

    useEffect(() => {
      if (value) getHoliday(value);
    }, [getHoliday, value]);

    useEffect(() => {
      if (autoFocus && ref) {
        const { current }: any = ref;
        if (current) {
          current?.setFocus();
        }
      }
    }, [ref, autoFocus]);

    const getDayName = (date: Date) => {
      return date.toLocaleDateString("ko-KR", { weekday: "long" }).substr(0, 1);
    };

    const getDayClassName = useCallback(
      (date: any) => {
        return (holiday?.holidayValues ?? []).some(
          (v: any) => v.locdate === format(date, "yyyy-MM-dd")
        )
          ? "holiday"
          : getDayName(new Date(date)) === "토"
          ? "saturday"
          : getDayName(new Date(date)) === "일"
          ? "sunday"
          : undefined;
      },
      [holiday?.holidayValues]
    );

    const onChangeDate = (v: any, e: any) => {
      // 년도 자릿수로 정상데이터 체크
      // 정상값 일 경우에만 onChange
      if (!v || String(getYear(v)).length === 4) onChange && onChange(v, e);
    };

    const onClickPrevMonth = (e: any, func: any) => {
      e.preventDefault();
      func();
    };

    const onClickNextMonth = (e: any, func: any) => {
      e.preventDefault();

      func();
    };

    return (
      <DatePickerWrapper
        // 스타일이 통일되지 않은 곳이 많아서 통일되기 전까지 사용
        style={{
          width: width ? width : "auto",
          ...props?.style,
        }}
        error={error}
      >
        <DatePicker
          ref={ref}
          showYearDropdown
          showMonthDropdown
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }: any) => (
            <div>
              <Wrapper>
                <ToolbarButton
                  data-testid="previous-month"
                  onClick={(e: any) => onClickPrevMonth(e, decreaseMonth)}
                  disabled={prevMonthButtonDisabled}
                >
                  <Icon variant="chevron_left" />
                </ToolbarButton>
                <DateText>{format(new Date(date), "yyyy.MM.dd (E)")}</DateText>
                <ToolbarButton
                  data-testid="next-month"
                  onClick={(e: any) => onClickNextMonth(e, increaseMonth)}
                  disabled={nextMonthButtonDisabled}
                >
                  <Icon variant="chevron_right" />
                </ToolbarButton>
              </Wrapper>
              <Wrapper>
                <div>
                  <NativeSelect
                    value={getYear(date)}
                    onChange={(v: any) => changeYear(Number(v))}
                    optionLabel="label"
                    optionValue="value"
                    options={years.map((v) => ({ label: `${v}년`, value: v }))}
                  />
                </div>
                <div>
                  <NativeSelect
                    value={months[getMonth(date)]}
                    onChange={(v: any) => changeMonth(months.indexOf(v))}
                    optionLabel="label"
                    optionValue="value"
                    options={months.map((v) => ({ label: `${v}월`, value: v }))}
                  />
                </div>
              </Wrapper>
            </div>
          )}
          dropdownMode="select"
          className={className}
          placeholderText={placeholder ?? "날짜선택"}
          dateFormat={dateFormat}
          selected={value ? new Date(value) : null}
          onChange={onChangeDate}
          onYearChange={getHoliday}
          onMonthChange={getHoliday}
          dayClassName={getDayClassName}
          popperProps={{
            positionFixed: true,
          }}
          disabled={disabled}
          maxDate={maxDate && new Date(maxDate)}
          minDate={minDate && new Date(minDate)}
          {...props}
        />
      </DatePickerWrapper>
    );
  }
);

export default observer(DateInput);
