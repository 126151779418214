import {
  SmallWrapper as _SmallWrapper,
  Wrapper,
  SelectBox,
} from "components/NativeSelect2.styled";

export const SmallWrapper = _SmallWrapper;

type NativeSelectProps = {
  value: any;
  onChange: (event?: any) => void;
  placeholder?: string;
  isRequire?: boolean;
  options: any[];
  optionValue?: string;
  optionLabel?: string;
  disabled?: boolean;
};

export const NativeSelect = ({
  value,
  onChange,
  placeholder,
  isRequire,
  options = [],
  optionValue = "id",
  optionLabel = "name",
  disabled,
  ...props
}: NativeSelectProps) => {
  const handleChange = (e: any) => {
    const value = e.target.value;
    if (value === placeholder) onChange(null);
    else onChange(value);
  };

  return (
    <Wrapper variant="outlined" {...props}>
      <SelectBox
        value={value || placeholder}
        onChange={handleChange}
        $invalid={!value || value === placeholder}
        disabled={disabled}
        native
        optioncolor={placeholder}
      >
        {placeholder && (
          <option value={placeholder} disabled={isRequire}>
            {placeholder}
          </option>
        )}
        {options.map((v) => (
          <option key={v[optionValue]} value={v[optionValue]}>
            {v[optionLabel]}
          </option>
        ))}
      </SelectBox>
    </Wrapper>
  );
};

export default NativeSelect;
