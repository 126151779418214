import { useEffect, useContext } from "react";
import { SimpleRegistrationsContext } from "pages/SimpleRegistrationsPage";
import { Box } from "@mui/material";
import { Container } from "components/SimpleRegistrations/Container";
import { Body } from "components/SimpleRegistrations/Body";
import { Footer } from "components/SimpleRegistrations/Footer";
import { LandscapeCompleteConetents } from "components/SimpleRegistrations/landscape/CompleteContents";
import { PortraitCompleteConetents } from "components/SimpleRegistrations/portrait/CompleteContents";

import { SlideModal } from "components/Modal/SlideModal";

export function SimpleRegistrationsCompleteModal({ open, onClose }) {
  const { isLandscape, name } = useContext(SimpleRegistrationsContext);

  useEffect(() => {
    const timeout = setTimeout(function () {
      onClose();
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [onClose]);

  return (
    <>
      <SlideModal open={open}>
        <Container>
          <Body>
            <Box
              sx={{
                display: "flex",
                justifyContent: isLandscape ? "center" : "flex-start",
                gap: "10%",
                alignItems: "center",
                height: "100%",
              }}
            >
              {isLandscape ? (
                <LandscapeCompleteConetents name={name} />
              ) : (
                <PortraitCompleteConetents name={name} />
              )}
            </Box>
          </Body>
          <Footer text="메인화면으로 이동" onClick={() => onClose()} />
        </Container>
      </SlideModal>
    </>
  );
}
