import { styled, css } from "@mui/material";
import { InnerBoxStyle } from "./Label";
import "assets/styles/datepicker.css";
import { ComponentType } from "react";

export const Wrapper = styled("div")`
  display: flex;
  align-items: center;
  column-gap: 8px;
  justify-content: center;
  height: 29px;
  margin-bottom: 10px;
`;

export const DatePickerWrapper = styled("div")`
  .react-datepicker-wrapper {
    width: 100%;
    height: 100%;
  }

  input {
    ${InnerBoxStyle}
    height: 29px;
    &::placeholder {
      color: #a1b1ca !important;
    }
    &:disabled {
      background-color: #f3f8ff;
      color: #4a4e70;
    }

    ${({ error }: any) =>
      error &&
      css`
        border-color: #f7685b !important;
      `}
  }
` as ComponentType<any>;

export const DateText = styled("div")`
  font-size: 15px;
  font-weight: 700;
  line-hieght: 22px;
`;
