import { Button } from "@mui/material";

export const DialButton = ({ text, onClick, sx, disabled }) => {
  return (
    <Button
      onClick={onClick}
      variant="outlined"
      disabled={disabled}
      sx={{
        borderColor: "#F1F1F1 !important",
        color: "black",
        fontSize: "4vw",
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1) !important",
        ...sx,
      }}
    >
      {text}
    </Button>
  );
};
