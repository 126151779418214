import { Box } from "@mui/material";
import { useUser } from "providers/user";

import portraitImage from "assets/images/background-portrait.png";
import { ReactComponent as UnocareLogo } from "assets/icons/ic-unocare-logo.svg";

export const TopSection = () => {
  const { user } = useUser();
  return (
    <Box
      sx={{
        width: "100vw",
        height: "30vh",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundImage: `url(${portraitImage})`,
          backgroundSize: "100% 100%",
          padding: "3%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            fontSize: "2vw",
            fontWeight: "bold",
            color: "white",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>{user.organization_top.name}</div>
          <UnocareLogo />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "5%",
          }}
        >
          <p
            style={{
              fontSize: "5vw",
              fontWeight: "bold",
              color: "white",
              margin: 0,
            }}
          >
            반갑습니다 ;) <br />
          </p>
          <p
            style={{
              fontSize: "3vw",
              fontWeight: "bold",
              color: "white",
              margin: 0,
            }}
          >
            전화번호로 접수를 시작합니다.
          </p>
        </Box>
      </Box>
    </Box>
  );
};
