import axios, { AxiosError, AxiosResponse } from "axios";
import { format } from "date-fns";
import * as Sentry from "@sentry/browser";
import { v4 as uuidv4 } from "uuid";

export class ApiException extends Error {
  public code;
  constructor(code: number, message: string, description: string) {
    super(message);
    this.code = code;
    this.name = message;
    this.message = description;
  }
}

const Event = {
  ACCESS_DENIED: "ACCESS_DENIED",
  ACCESS_RESTRICTED: "ACCESS_RESTRICTED",
  OWNER_ACCESS_DENIED: "OWNER_ACCESS_DENIED",
  UNAUTHORIZED: "UNAUTHORIZED",
  REFRESH_TOKEN: "REFRESH_TOKEN",
};

export class ApiClient extends EventTarget {
  public endpoint;
  static Event = Event;
  private client: any;
  private accessToken: any;
  private refreshToken: any;

  constructor({ endpoint }: { endpoint: string | undefined }) {
    super();
    this.endpoint = endpoint;
  }

  setTokens(accessToken: string, refreshToken: string) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
  }

  buildHeaders(userOptions = {}) {
    const defaultArgs = {
      contentType: "application/json",
      refresh: false,
    };

    const options = Object.assign(defaultArgs, userOptions);

    /**
     * @type {Object.<string, string>}
     */
    const headers: any = {};

    if (options.contentType) {
      headers["Content-Type"] = options.contentType;
    }

    if (options.refresh) {
      headers["Authorization"] = `Bearer ${this.refreshToken}`;
    } else {
      if (this.accessToken) {
        headers["Authorization"] = `Bearer ${this.accessToken}`;
      }
    }

    return headers;
  }

  on(type: string, listener: any) {
    const handler = async (e: any) => listener(e.detail);
    this.addEventListener(type, handler);
    return () => {
      this.removeEventListener(type, handler);
    };
  }

  _dispatch(eventName: string, payload?: any) {
    this.dispatchEvent(new CustomEvent(eventName, { detail: payload }));
  }

  async request(
    request_func: any,
    options?: any,
    retries?: number
  ): Promise<any> {
    const requestOptions = Object.assign(
      {
        retry: true,
      },
      options
    );

    const res = await request_func();

    if (res.ok) return res;

    let errorObject = "";
    await res.text().then((text: string) => (errorObject = text));
    const message = JSON.parse(errorObject)?.description;
    const name = JSON.parse(errorObject)?.name;

    if (!res.ok) {
      if (res.status === 401 && requestOptions.retry && (retries || 0) === 0) {
        try {
          await this.refreshAccessToken();
          return await this.request(request_func, options, (retries || 0) + 1);
        } catch (e) {
          this._dispatch(ApiClient.Event.UNAUTHORIZED);
          throw e;
        }
      }

      if (res.status === 403 && name === "ACCESS_DENIED") {
        this._dispatch(ApiClient.Event.ACCESS_DENIED);
      }

      if (res.status === 403 && name === "OWNER_ACCESS_DENIED") {
        this._dispatch(ApiClient.Event.OWNER_ACCESS_DENIED);
      }

      if (res.status === 403 && name === "ACCESS_RESTRICTED") {
        this._dispatch(ApiClient.Event.ACCESS_RESTRICTED);
      }

      throw new ApiException(res.status, name || message, message);
    }
  }

  async fetch(endpoint: string, option?: any) {
    const requestId = uuidv4();

    Sentry.addBreadcrumb({
      type: "HTTP Request",
      category: "fetch.start",
      message: `[${requestId}] ${endpoint}`,
      level: Sentry.Severity.Info,
    });

    try {
      const result = await fetch(endpoint, option);
      Sentry.addBreadcrumb({
        type: "HTTP Request",
        category: "fetch.success",
        message: `[${requestId}] ${endpoint} ${result.status}`,
        level: Sentry.Severity.Info,
      });

      return result;
    } catch (e: any) {
      Sentry.addBreadcrumb({
        type: "HTTP Request",
        category: "fetch.failure",
        message: `[${requestId}] ${endpoint} ${e.message}`,
        level: Sentry.Severity.Info,
      });
      throw e;
    }
  }

  async get(path: string, params?: any) {
    return this.request(() =>
      this.fetch(`${this.endpoint}${path}?` + new URLSearchParams(params), {
        method: "GET",
        headers: this.buildHeaders(),
      })
    );
  }

  async post(path: string, payload?: any, options?: any) {
    return this.request(
      () =>
        this.fetch(`${this.endpoint}${path}`, {
          method: "POST",
          headers: this.buildHeaders(options?.headers),
          body: payload ? JSON.stringify(payload) : payload,
        }),
      options
    );
  }

  async put(path: string, payload: any, options?: any) {
    return this.request(
      () =>
        this.fetch(`${this.endpoint}${path}`, {
          method: "PUT",
          headers: this.buildHeaders(),
          body: JSON.stringify(payload),
        }),
      options
    );
  }

  async delete(path: string, payload?: any, options?: any) {
    return this.request(
      () =>
        this.fetch(`${this.endpoint}${path}`, {
          method: "DELETE",
          headers: this.buildHeaders(),
          body: JSON.stringify(payload),
        }),
      options
    );
  }

  async multipart(path: string, payload: any, options?: any) {
    return this.request(async () => {
      const formData = new FormData();

      for (const name in payload) {
        formData.append(name, payload[name]);
      }

      return await this.fetch(`${this.endpoint}${path}`, {
        method: "POST",
        headers: this.buildHeaders({
          contentType: null,
        }),
        body: formData,
      });
    }, options);
  }

  async signin(email: string, password: string) {
    return this.post(
      "/users/sign_in",
      { email, password },
      {
        retry: false,
      }
    );
  }

  async confirmCertification(email: string, confirmNumber: string) {
    return this.post(
      "/users/otp/confirm",
      { email, otpCode: confirmNumber },
      {
        retry: false,
      }
    );
  }

  async sendCertificationEmail(email: string) {
    return this.client.post("/admin/users/otp", { email });
  }

  async changePassword(password: string) {
    return this.put(
      "/users/change_password",
      { password },
      {
        retry: false,
      }
    );
  }

  async me() {
    return this.get("/users/me");
  }

  async getDepartments() {
    return this.get("/departments/categories", {
      limit: 300,
      hasChild: true,
      visible: true,
    });
  }

  async getTickets(params: any) {
    return this.get("/tickets", params);
  }

  async getAcquisitionChannels(params: any) {
    return this.get("/customers/acquisition_channels", {
      limit: 300,
      visible: true,
      ...params,
    });
  }

  async getDuty(type: string) {
    return this.get("/users/duty", {
      limit: 300,
      userStatus: "active",
      duty: type,
    });
  }

  async getTreatmentItems() {
    return this.get("/treatment_items/categories", {
      limit: 10000,
      visible: true,
      orderBy: "order asc",
    });
  }

  async getComplaints() {
    return this.get("/customers/complaints", {
      limit: 300,
      visible: true,
    });
  }

  async getRegions() {
    return this.get("/customers/regions", {
      limit: 300,
      visible: true,
    });
  }

  async getLevels() {
    return this.get("/customers/levels", {
      limit: 300,
      visible: true,
    });
  }

  async getJobs() {
    return this.get("/customers/jobs", {
      limit: 300,
      visible: true,
    });
  }

  async getRegistrationCheck(phoneNumber: string) {
    return this.get("/customers/registration/check", {
      phoneNumber: phoneNumber,
    });
  }

  async getSmsNotifications() {
    return this.get("/sms_rules", {
      limit: 1000,
      situations: "reserved",
      visible: true,
    });
  }

  async createAppointment(data: any) {
    return this.post("/appointments", data);
  }

  async getAppointment(appointmentId: string) {
    return this.get(`/appointments/${appointmentId}`);
  }

  async editAppointment(appointmentId: string, data: any) {
    return this.put(`/appointments/${appointmentId}`, data);
  }

  async deleteAppointment(appointmentId: string) {
    return this.delete(`/appointments/${appointmentId}`);
  }

  async getConfig(key: string) {
    return this.get("/appointments/v2/calendar/configs", {
      configKey: key,
    });
  }

  async getStatusSettings() {
    return await this.get("/sessions/session_status_config");
  }

  async resotreAppointment(appointmentId: string, params: any) {
    return this.post(`/appointments/${appointmentId}/restore`, params);
  }

  async getSessionsSimple(params: any) {
    return this.get("/sessions/simple", params);
  }

  async getSessions(params: any) {
    return this.get("/sessions", params);
  }

  async getSessionsHistories(params: any) {
    return this.get("/sessions/histories", params);
  }

  async cancelSessions(id: string) {
    return this.post(`/sessions/${id}/cancel`);
  }

  async getRegistration(registrationId: string) {
    return this.get(`/registrations/${registrationId}`);
  }

  async createRegistration(data: any) {
    return this.post("/registrations", data);
  }

  async createRegistrationSimple(data: {
    appointmentId: number;
    sessionStatusConfigId: string;
    sessionConfigId: number;
  }) {
    return this.post("/registrations/simple", data);
  }

  async editRegistration(registrationId: string, data: any) {
    return this.put(`/registrations/${registrationId}`, data);
  }

  async deleteRegistration(registrationId: string) {
    return this.delete(`/registrations/${registrationId}`);
  }

  async revertRegistration(registrationId: string, params?: any) {
    return this.post(`/registrations/${registrationId}/revert`, params);
  }

  async createRegistrationRequests(data: any) {
    return this.post("/registrations/requests", data);
  }

  async getAppointmentsCount(date = new Date(), departmentId: string) {
    const d = format(new Date(date), "yyyy-MM-dd");
    const p: any = { startAt: d, endAt: d };
    if (departmentId) p.departmentIds = departmentId;
    return this.get("/sessions/stats", p);
  }

  async getAppointmentsTime(date = new Date(), departmentId: string) {
    return this.get("/sessions/occupations", {
      date: format(new Date(date), "yyyy-MM-dd"),
      departmentId: departmentId,
    });
  }

  async searchCustomer(params: any, limit?: number) {
    return this.get(`/customers`, {
      orderBy: "lastVisitAt desc, id desc ",
      limit: limit ? limit : 40,
      ...params,
    });
  }

  async getUserDuty(params: any) {
    return this.get(`/users/duty`, {
      ...params,
    });
  }

  async createCustomer(data: any) {
    return this.post(`/customers`, data);
  }

  async editCustomer(customerId: string, data: any) {
    return this.put(`/customers/${customerId}`, data);
  }

  async getCustomer(customerId: string) {
    return this.get(`/customers/${customerId}`);
  }

  async deleteCustomer(customerId: string) {
    return this.delete(`/customers/${customerId}`);
  }

  async getClinicDrive(clinicId: string) {
    return this.get(`/drives/clinics/${clinicId}`);
  }

  async getCustomerDrive(customerId: string) {
    return this.get(`/drives/customers/${customerId}`);
  }

  async getDirectories(params: { directoryId: string; orderBy: string }) {
    const { directoryId, orderBy } = params;
    return this.get(`/directories`, {
      parentId: directoryId,
      orderBy,
    });
  }

  async getDirectory(directoryId: string) {
    return this.get(`/directories/${directoryId}`);
  }

  async getFiles(params: {
    directoryId: string;
    page?: number;
    limit?: number;
    orderBy?: string;
    name?: string;
  }) {
    return this.get("/files", { ...params });
  }

  async getFile(fileId: string) {
    return this.get(`/files/${fileId}`);
  }

  async updateFile(id: string, payload: any) {
    return this.put(`/files/${id}`, payload);
  }

  async getImage(imageId: string) {
    return this.get(`/images/${imageId}`);
  }

  async uploadImages(token: any) {
    return this.post("/images", { token });
  }

  async createImage(extension: string) {
    const payload: any = {};
    if (extension !== "") {
      payload.extension = extension;
    }
    return this.post("/images/uploads", payload);
  }

  async uploadImage(
    url: string,
    file: File
  ): Promise<{ progress: number; response?: AxiosResponse }> {
    const MAX_RETRIES = 3;
    const RETRY_DELAY = 2000;

    let retries = 0;
    let lastProgress = 0;

    const upload = async (): Promise<{
      progress: number;
      response?: AxiosResponse;
      success: boolean;
    }> => {
      try {
        const response = await axios.put(url, file, {
          headers: {
            "Content-Type": file.type,
          },
          onUploadProgress: (progressEvent) => {
            if (progressEvent.total) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              if (percentCompleted > lastProgress) {
                console.log(`Upload progress: ${percentCompleted}%`);
                lastProgress = percentCompleted;
              }
            }
          },
        });
        console.log(`Upload completed: ${lastProgress}%`);
        return { progress: lastProgress, response, success: true };
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError;
          if (
            axiosError.code === "ECONNABORTED" ||
            axiosError.code === "ERR_NETWORK" ||
            !axiosError.response
          ) {
            // 네트워크 오류 또는 연결 중단
            if (retries < MAX_RETRIES) {
              retries++;
              console.log(
                `Upload interrupted at ${lastProgress}%. Retrying (${retries}/${MAX_RETRIES})...`
              );
              await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
              return upload(); // 재귀적으로 다시 시도
            } else {
              console.error(
                `Upload failed after ${MAX_RETRIES} retries. Last progress: ${lastProgress}%`
              );
              return {
                progress: lastProgress,
                success: false,
              };
            }
          } else {
            console.error(
              `Upload failed at ${lastProgress}%: ${axiosError.message}`
            );
            return {
              progress: lastProgress,
              success: false,
            };
          }
        } else {
          console.error(
            `An unexpected error occurred during upload. Last progress: ${lastProgress}%`
          );
          return {
            progress: lastProgress,
            success: false,
          };
        }
      }
    };

    return upload();
  }

  async getBookmarksImageList() {
    return this.get(`/files/boilerplate/images`);
  }

  async uploadBookmarkImage(token: any) {
    return this.post("/files/boilerplate/images", { token });
  }

  async createBookmarkImage(extension: string) {
    const payload: any = {};
    if (extension !== "") {
      payload.extension = extension;
    }
    return this.post("/files/boilerplate/images/upload", payload);
  }

  async uploadBookmarkImageFile(url: string, payload: any) {
    const res = await fetch(url, {
      method: "PUT",
      contentType: "multipart/form-data",
      body: payload,
    } as any);
    if (!res.ok) {
      const text = await res.text();
      throw new Error(`Presigned url post failed. ${res.status} ${text}`);
    }
  }

  async deleteBookmarkImage(id: string) {
    return this.delete(`/files/boilerplate/images/${id}`);
  }

  async getMemoBoilerPlates(category: string) {
    return this.get(`/boilerplate_memos`, {
      limit: 5,
      orderBy: "order asc",
      category: category,
    });
  }

  async getPenchartBoilerplates() {
    return this.get(`/files/boilerplatemessages`, {
      limit: 300,
    });
  }

  async createPenchartBoilerplate(message: string) {
    return this.post(`/files/boilerplatemessages`, {
      contents: message,
    });
  }

  async deletePenchartBoilerplate(id: string) {
    return this.delete(`/files/boilerplatemessages/${id}`);
  }

  async deleteDirectory(id: string) {
    return this.delete(`/directories/${id}`);
  }

  async copyDirectory(srcId: string, parentId: string) {
    return this.post(`/directories/copy`, {
      srcId,
      destId: parentId,
    });
  }

  async updateDirectory(id: string, payload: any) {
    return this.put(`/directories/${id}`, payload);
  }

  async deleteFile(id: string) {
    return this.delete(`/files/${id}`);
  }

  async createFile(payload: any) {
    return this.post(`/files`, payload);
  }

  async copyFile(srcId: string, parentId: string) {
    return this.post(`/files/copy`, {
      srcId,
      destId: parentId,
    });
  }

  async refreshAccessToken() {
    const res = await this.post(`/users/refresh`, null, {
      retry: false,
      headers: {
        refresh: true,
      },
    });

    const { accessToken, refreshToken } = await res.json();

    this._dispatch(ApiClient.Event.REFRESH_TOKEN, {
      accessToken,
      refreshToken,
    });

    return;
  }

  async getClinic(clinicId: string) {
    return await this.get(`/clinics/${clinicId}`);
  }

  async getConfigs(params: any) {
    return await this.get(`/clinics/configs`, params);
  }

  async getHolidays(params: any) {
    return await this.get("/holidays", params);
  }

  async getPopupList() {
    return await this.get("/popups");
  }

  async getExternalLinkedChannel() {
    return await this.get("/external_linked/channels");
  }
}
