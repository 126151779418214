import { ReactNode } from "react";
import { ToolbarButton as _ToolbarButton } from "components/ToolbarButton.styled";

type ToolbarButtonProps = {
  active?: boolean;
  onClick?: () => void;
  paddingSize?: number;
  children?: ReactNode;
  className?: string;
};

export const ToolbarButton = (props: ToolbarButtonProps) => {
  return <_ToolbarButton {...props} />;
};
