import { Box, IconButton } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { observer } from "mobx-react";
import { useUser } from "providers/user";
import { useAuth } from "providers/auth";
import landscapeImage from "assets/images/background-landscape.png";
import { ReactComponent as UnocareLogo } from "assets/icons/ic-unocare-logo.svg";
import { useSimpleRegistration } from "hooks/useStore";

export const LeftSection = observer(() => {
  const { getHiddenCondition } = useSimpleRegistration();
  const { user } = useUser();
  const { signout } = useAuth();

  const handleClick = () => {
    signout();
  };

  return (
    <Box
      sx={{
        width: "50vw",
        height: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundImage: `url(${landscapeImage})`,
          backgroundSize: "100% 100%",
          padding: "10%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            fontSize: "2vw",
            fontWeight: "bold",
            color: "white",
          }}
        >
          {getHiddenCondition ? (
            <IconButton
              onClick={handleClick}
              sx={{
                color: "#ffffff",
                width: "60px",
                height: "60px",
              }}
            >
              <LogoutIcon />
            </IconButton>
          ) : (
            user.organization_top.name
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "5%",
          }}
        >
          <p
            style={{
              fontSize: "5vw",
              fontWeight: "bold",
              color: "white",
              margin: 0,
            }}
          >
            반갑습니다 {getHiddenCondition ? "🤫" : ";)"} <br />
          </p>
          <p
            style={{
              fontSize: "3vw",
              fontWeight: "bold",
              color: "white",
            }}
          >
            전화번호로 <br />
            접수를 시작합니다.
          </p>
        </Box>
        <UnocareLogo />
      </Box>
    </Box>
  );
});
