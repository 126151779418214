import styled from "@emotion/styled";
import { Box, Button } from "@mui/material";

const Wrapper = styled.div`
  flex: 2;
  margin-bottom: 1%;
  padding: 0 3%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const Footer = ({ text, disabled, onClick }) => {
  return (
    <Wrapper>
      <Box>
        <Button
          variant="contained"
          disabled={disabled}
          sx={{
            width: "100%",
            height: "10vh",
            fontSize: "3vw",
          }}
          onClick={() => onClick(true)}
        >
          {text}
        </Button>
      </Box>
    </Wrapper>
  );
};
